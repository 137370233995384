import React from 'react'
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  FloatingFocusManager,
  FloatingOverlay,
  useId,
  useTransitionStatus,
} from '@floating-ui/react'
import { CartButton } from '../CartButton'
import { CartItem as Item } from '../CartItem'
import { Close, FREQUENCY, Metadata, Shield, Z_INDEX_MAP } from '@classy/campaign-page-blocks'
import { EVENT } from 'models/event'
import { CartItem } from '../../Cart.model'
import * as Styled from './CartDialog.styled'

interface CartDialogProps {
  checkoutBaseUrl: string
  checkoutQueryParams: Metadata['forwardCheckoutQueryParams']
  cartId?: string
  cartCount?: number
  cartTotal?: number
  cartItems?: CartItem[]
  cartCountLimit: number
  showCartLimitExceededError: boolean
  setShowCartLimitExceededError: (status: boolean) => void
  onItemDelete: (cartItemId: string) => void
  isLoading: boolean
}

export const CartDialog = ({
  checkoutBaseUrl,
  checkoutQueryParams,
  cartId,
  cartTotal = 0,
  cartCount = 0,
  cartItems = [],
  cartCountLimit,
  showCartLimitExceededError,
  setShowCartLimitExceededError,
  onItemDelete,
  isLoading,
}: CartDialogProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
  })

  const { isMounted, status } = useTransitionStatus(context)

  const click = useClick(context)
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  })
  const role = useRole(context)

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  // Set up label and description ids
  const labelId = useId()
  const descriptionId = useId()

  const totalAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(cartTotal))

  React.useEffect(() => {
    document.addEventListener(EVENT.CART_OPEN_MODAL, () => setIsOpen(true))
    return () => {
      document.removeEventListener(EVENT.CART_OPEN_MODAL, () => setIsOpen(true))
    }
  }, [])

  const cartIsEmpty = cartCount <= 0

  let checkoutUrl = ''
  if (cartId) {
    const checkoutUrlParams = new URLSearchParams(checkoutQueryParams)
    checkoutUrlParams.append('cart_id', cartId)
    checkoutUrl = `${checkoutBaseUrl}/checkout?${checkoutUrlParams}`
  }

  // Logic for swiping to close Modal
  const [touchStart, setTouchStart] = React.useState<number | null>(null)
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null)

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) =>
    setTouchEnd(e.targetTouches[0].clientY)

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isDownSwipe = distance < -minSwipeDistance
    if (isDownSwipe) setIsOpen(false)
  }
  // End Swiping Logic

  const oneTimeDonationItems = React.useMemo(
    () => cartItems.filter((item) => !item.recurringProperties?.frequency),
    [cartItems],
  )

  const recurringDonationItems = React.useMemo(
    () => cartItems.filter((item) => item.recurringProperties?.frequency),
    [cartItems],
  )

  React.useEffect(() => {
    if (!isOpen) {
      setShowCartLimitExceededError(false)

      // Notify page blocks that the cart has been closed (see Donation and Impact for examples)
      document.dispatchEvent(new CustomEvent(EVENT.CART_MODAL_CLOSED, { detail: { closed: true } }))
    }
  }, [isOpen, setShowCartLimitExceededError])

  return (
    <>
      <CartButton
        cartCount={cartCount}
        isLoading={isLoading}
        innerRef={refs.setReference}
        {...getReferenceProps()}
      />

      {(isMounted || isOpen) && (
        <FloatingOverlay
          lockScroll
          style={{
            zIndex: Z_INDEX_MAP.MODAL_OVERLAY,
            overflow: 'hidden',
            background: 'rgba(0, 0, 0, 0.7)',
          }}
        >
          <FloatingFocusManager context={context}>
            <Styled.CartContainer
              ref={refs.setFloating}
              aria-labelledby={labelId}
              aria-describedby={descriptionId}
              aria-modal="true"
              {...getFloatingProps()}
              className="cart-container"
              data-transition-status={status}
            >
              {/* FIXME: should we have an error state for when cartId is not invalid?
                  It starts off as null. Is there a way to guarantee cartId on page load
                  via getServerSideProps()? */}
              <Styled.CartHeader
                id={labelId}
                onTouchStart={(e) => onTouchStart(e)}
                onTouchMove={(e) => onTouchMove(e)}
                onTouchEnd={() => onTouchEnd()}
              >
                <Styled.Title>Your Cart</Styled.Title>
                <Styled.Close aria-label="Close cart" onClick={() => setIsOpen(false)}>
                  <Close />
                </Styled.Close>
              </Styled.CartHeader>
              <Styled.CartBody id={descriptionId}>
                {showCartLimitExceededError && isOpen && (
                  <Styled.CartError aria-live="assertive">
                    Item not added. Cart cannot exceed {cartCountLimit} items.
                  </Styled.CartError>
                )}
                <Styled.ScrollableCartBody>
                  {oneTimeDonationItems.length > 0 && (
                    <Styled.CartSection>
                      <Styled.FrequencyLabel>One-time donations</Styled.FrequencyLabel>
                      {oneTimeDonationItems.map(
                        (item) =>
                          item.id && (
                            <Item
                              id={item.id}
                              key={item.id}
                              title={item.name}
                              description={item.description}
                              frequency={FREQUENCY.ONE_TIME}
                              amount={item.unitPrice}
                              onDelete={onItemDelete}
                              currency="USD"
                              endDate={item.recurringProperties?.endDate}
                            />
                          ),
                      )}
                    </Styled.CartSection>
                  )}
                  {recurringDonationItems.length > 0 && (
                    <Styled.CartSection>
                      <Styled.FrequencyLabel>Recurring donations</Styled.FrequencyLabel>
                      {recurringDonationItems.map(
                        (item) =>
                          item.id && (
                            <Item
                              id={item.id}
                              key={item.id}
                              title={item.name}
                              description={item.description}
                              frequency={item.recurringProperties?.frequency}
                              amount={item.unitPrice}
                              onDelete={(id) => {
                                onItemDelete(id)
                              }}
                              currency="USD"
                              endDate={item.recurringProperties?.endDate}
                            />
                          ),
                      )}
                    </Styled.CartSection>
                  )}
                  {cartIsEmpty && (
                    <Styled.EmptyCart>There are no items in your cart.</Styled.EmptyCart>
                  )}
                </Styled.ScrollableCartBody>
              </Styled.CartBody>
              <Styled.CartFooter>
                <Styled.SubtotalContainer>
                  Subtotal
                  <Styled.SubtotalItems>
                    {cartCount} {cartCount === 1 ? 'item' : 'items'}
                  </Styled.SubtotalItems>
                  {totalAmount}
                </Styled.SubtotalContainer>
                <Styled.CheckoutButton
                  {...(!cartIsEmpty
                    ? { href: checkoutUrl }
                    : { role: 'link', 'aria-disabled': 'true' })}
                >
                  Continue to checkout
                </Styled.CheckoutButton>
                <Styled.SecurityNote>
                  <Shield width="18" height="24" />
                  Transactions are secure and encrypted
                </Styled.SecurityNote>
              </Styled.CartFooter>
            </Styled.CartContainer>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </>
  )
}
