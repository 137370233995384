import React from 'react'
import { EVENT } from 'models/event'
import {
  AmountInput,
  Donation,
  Metadata,
  ParentLayoutInfo,
  DonationAmount,
  DonationProps,
  useCustomEventListener,
  DonationBlockOnInitialization,
} from '@classy/campaign-page-blocks'
import { iaaDefaultAmounts, addDonationBlockProps } from './donation'
import { useRouter } from 'next/router'
import { trackDonationFormInitEvent } from 'services/analytics/events/donationFormInitialization'

interface DonationControllerProps extends DonationProps {
  metadata?: Metadata
  parentLayoutInfo?: ParentLayoutInfo
  sectionId?: string
}

// FIXME: as part of CL-28526,
// we will want to use DonationSelfProps in place of DonationControllerProps
export const DonationController = ({ metadata, ...rest }: DonationControllerProps) => {
  const router = useRouter()
  const { query } = router
  const urlAmountUsed = query.amount as string

  const intelligentAskAmountData = useCustomEventListener(EVENT.IAA_FETCH_AMOUNTS, {
    isLoading: true,
    suggested_donation_amount: null,
    suggested_recurring_amount: null,
    grid: {
      suggested_donation_amount: null,
      suggested_recurring_amount: null,
    },
  })

  const onInitialization = ({ ...initBlockstate }: DonationBlockOnInitialization) => {
    trackDonationFormInitEvent({ initBlockstate, urlAmountUsed, sectionId: rest.sectionId })
  }

  if (
    !metadata?.intelligentAskAmount?.onetimeEnabled &&
    !metadata?.intelligentAskAmount?.recurringEnabled
  ) {
    return <Donation {...rest} metadata={metadata} onInitialization={onInitialization} />
  }
  interface BuildAmountsArrayParams {
    iaaAmountArray: number[]
  }

  const buildAmountsArray = ({ iaaAmountArray }: BuildAmountsArrayParams): DonationAmount[] => {
    const amountArray = iaaAmountArray.map((amount: number, index: number) => {
      return {
        name: `preset${index + 1}` as AmountInput,
        amount: amount,
        isDefault: false,
        isVisible: true,
      }
    })

    return [
      ...amountArray,
      {
        name: 'other' as AmountInput,
        amount: 0,
        isVisible: true,
        isDefault: false,
      },
    ]
  }

  const {
    suggested_donation_amount: suggestedDonationAmount,
    suggested_recurring_amount: suggestedRecurringAmount,
    grid: {
      suggested_donation_amount: suggestedDonationAmountArray,
      suggested_recurring_amount: suggestedRecurringAmountArray,
    },
  } = intelligentAskAmountData

  const useDonationDefaultAmounts = !suggestedDonationAmount || !suggestedDonationAmountArray
  const useRecurringDefaultAmounts = !suggestedRecurringAmount || !suggestedRecurringAmountArray

  const donationAmounts = useDonationDefaultAmounts
    ? iaaDefaultAmounts.donationAmounts
    : buildAmountsArray({ iaaAmountArray: suggestedDonationAmountArray })

  const recurringAmounts = useRecurringDefaultAmounts
    ? iaaDefaultAmounts.recurringAmounts
    : buildAmountsArray({ iaaAmountArray: suggestedRecurringAmountArray })

  const overrideProps = {
    ...rest,
    recurringAmounts: metadata.intelligentAskAmount?.recurringEnabled
      ? recurringAmounts
      : rest.recurringAmounts,
    donationAmounts: metadata.intelligentAskAmount?.onetimeEnabled
      ? donationAmounts
      : rest.donationAmounts,
  }

  addDonationBlockProps(overrideProps, query)

  return (
    <Donation
      {...overrideProps}
      onInitialization={onInitialization}
      metadata={metadata}
      isLoading={intelligentAskAmountData.isLoading}
      iaaData={{
        suggestedDonationAmount: suggestedDonationAmount,
        suggestedRecurringAmount: suggestedRecurringAmount,
      }}
    />
  )
}
