import React, { useState } from 'react'
import { Metadata, useCustomEventListener, dispatchCustomEvent } from '@classy/campaign-page-blocks'
import { EVENT } from 'models/event'
import * as cartService from 'services/cart/cart'
import { useCartLocalStorageReducer } from './hooks/useCartLocalStorageReducer'
import { CartItem } from './Cart.model'
import { CartDialog } from './components/CartDialog'
import { logger } from 'utils/logger'
import { isWhammyApiError } from 'utils/WhammyApiError'

interface CartControllerProps {
  checkoutBaseUrl: string
  checkoutQueryParams: Metadata['forwardCheckoutQueryParams']
  campaignId: string
}

const CART_COUNT_LIMIT = 40

export const CartController = ({
  checkoutBaseUrl,
  checkoutQueryParams,
  campaignId,
}: CartControllerProps) => {
  const [isLoading, setisLoading] = React.useState(true)

  const initialCart = React.useMemo(() => cartService.initCart(campaignId), [campaignId])

  const [cartState, dispatch] = useCartLocalStorageReducer(campaignId, initialCart, setisLoading)

  const cartCount = React.useMemo(() => cartState.items?.length, [cartState])
  const cartTotal = React.useMemo(
    () => cartState.items?.reduce((a, b) => a + b.unitPrice, 0),
    [cartState?.items],
  )

  const [showCartLimitExceededError, setShowCartLimitExceededError] = useState(
    (cartCount !== undefined && cartCount > CART_COUNT_LIMIT) || false,
  )

  /**
   * When developing locally, if you add an item to the cart, and then make a code change, that
   * previous cart item will be added to the cart again. This is due to Next.js's Fast Refresh
   * feature which maintains component state.
   * TODO: Is there a way to avoid that, does it matter?
   */
  const newCartItem = useCustomEventListener<CartItem | null>(EVENT.CART_ADD_ITEM, null)

  const createNewCart = React.useCallback(
    async (cartItem: CartItem) => {
      const cart = { ...cartState, items: [cartItem] }

      logger('trace', 'Creating a new cart', { context: { cart } })

      try {
        const newCart = await cartService.createCart(cart, campaignId)
        dispatch({ type: 'REPLACE_CART', newCart })

        dispatchCustomEvent(EVENT.ANALYTICS_TRACK_ADD_TO_CART, {
          newCartItem: newCart?.items?.[0],
          cartCount: 1,
        })
      } catch (e) {
        logger('error', new Error('Unable to create new cart', { cause: e }), {
          context: { cart },
        })
        dispatch({ type: 'DELETE_LOCAL_STORAGE' })
      }
    },
    [campaignId, cartState, dispatch],
  )

  const addItemToCart = React.useCallback(
    async (cartItem: CartItem) => {
      if (!cartState || !cartState.id) {
        return
      }

      logger('trace', 'Adding item to cart', { context: { cartState } })

      try {
        const newCartItem = await cartService.addCartItem(cartState.id, cartItem, campaignId)
        dispatch({ type: 'ADD_CART_ITEM', cartItem: newCartItem })

        dispatchCustomEvent(EVENT.ANALYTICS_TRACK_ADD_TO_CART, {
          newCartItem,
          cartCount: Number(cartCount) + 1,
        })
      } catch (e) {
        logger('error', new Error('Unable to add item to cart', { cause: e }), {
          context: { cartState, cartItem },
        })

        if (isWhammyApiError(e)) {
          switch (e.statusCode) {
            case 403:
              dispatch({ type: 'DELETE_LOCAL_STORAGE' })
              break
            case 422:
              if (e.message === 'Cart maximum capacity exceeded') {
                setShowCartLimitExceededError(true)
              }
              break
          }
        }
      }
    },
    [campaignId, cartState, dispatch, cartCount],
  )

  const removeItemFromCart = React.useCallback(
    async (cartItemId: string) => {
      if (!cartState || !cartState.id) {
        return
      }

      logger('trace', 'Removing item from cart', { context: { cartItemId, cartState } })

      try {
        await cartService.deleteCartItem(cartState.id, cartItemId, campaignId)
        dispatch({ type: 'REMOVE_CART_ITEM', cartItemId })

        if (showCartLimitExceededError) setShowCartLimitExceededError(false)
      } catch (e) {
        logger('error', new Error('Unable to remove cart item', { cause: e }), {
          context: { cartState, cartItemId },
        })

        if (isWhammyApiError(e) && e.statusCode === 403) {
          dispatch({ type: 'DELETE_LOCAL_STORAGE' })
        }
      }
    },
    [campaignId, cartState, dispatch, showCartLimitExceededError],
  )

  React.useEffect(() => {
    if (!newCartItem) {
      return
    }

    if (cartState.id) {
      addItemToCart(newCartItem)
    } else {
      createNewCart(newCartItem)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only trigger for new cart items
  }, [newCartItem])

  return (
    <CartDialog
      checkoutBaseUrl={checkoutBaseUrl}
      checkoutQueryParams={checkoutQueryParams}
      cartId={cartState.id}
      cartCount={cartCount}
      cartTotal={cartTotal}
      cartItems={cartState.items}
      onItemDelete={removeItemFromCart}
      showCartLimitExceededError={showCartLimitExceededError}
      setShowCartLimitExceededError={setShowCartLimitExceededError}
      cartCountLimit={CART_COUNT_LIMIT}
      isLoading={isLoading}
    />
  )
}
