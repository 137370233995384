import {
  AmountInput,
  DonationAmount,
  DonationFrequency,
  DonationProps,
  FREQUENCY,
} from '@classy/campaign-page-blocks'
import { ParsedUrlQuery } from 'querystring'

/**
 * Donation block related functions.
 */

const RECURRING_QUERY_PARAM_MAP = {
  '0': FREQUENCY.ONE_TIME,
  '1': FREQUENCY.YEARLY,
  '2': FREQUENCY.SEMI_ANNUALLY,
  '4': FREQUENCY.QUARTERLY,
  '12': FREQUENCY.MONTHLY,
  '26': FREQUENCY.BI_WEEKLY,
  '52': FREQUENCY.WEEKLY,
  '365': FREQUENCY.DAILY,
} as const

type RecurringQueryParamKey = keyof typeof RECURRING_QUERY_PARAM_MAP

/**
 * Donation page block props overrides.
 *
 * Expected query params:
 * - preset[1-4] = (string) donation amount, e.g. "100.00"
 * - recurring = (string) frequency code (see RECURRING_QUERY_PARAM_MAP)
 * - amount = (string) donation amount, e.g. "100.00"
 *
 * Note, amount is always processed last. If it is not processed last, you
 * could wind up in weird scenarios (see donation.test.ts for an example).
 */
export const addDonationBlockProps = (blockProps: DonationProps, queryParams: ParsedUrlQuery) => {
  // Override presets with new amounts
  if (queryParams.preset1 || queryParams.preset2 || queryParams.preset3 || queryParams.preset4) {
    applyPresetOverride(queryParams, blockProps.donationAmounts)
    applyPresetOverride(queryParams, blockProps.recurringAmounts)
  }

  // Override the default frequency
  if (queryParams.recurring) {
    const frequencyCode = queryParams.recurring as RecurringQueryParamKey
    applyFrequencyOverride(frequencyCode, blockProps.donationFrequencies)
  }

  // Change default preset or, if amount does not match a preset, default to the other preset
  if (queryParams.amount) {
    const defaultAmount = Math.abs(Number(queryParams.amount))
    applyAmountOverride(defaultAmount, blockProps.donationAmounts)
    applyAmountOverride(defaultAmount, blockProps.recurringAmounts)
  }
}

/**
 * The "preset*" overrides (preset1, preset2, preset3, preset4) change the donation
 * amounts on any of the four donation buttons. The change will be applied even
 * if the preset is not the default and/or not visible.
 *
 * For example, "preset1=100" would change the top left button to display "$100".
 *
 * Presets are applied from the top left, clockwise:
 * preset1 preset2
 * preset3 preset4
 */
function applyPresetOverride(queryParams: ParsedUrlQuery, amountList?: DonationAmount[]) {
  amountList?.forEach((amountObj) => {
    const presetName = amountObj.name
    if (queryParams[presetName]) {
      amountObj.amount = Number(queryParams[presetName])
    }
  })
}

/**
 * The "recurring" override changes the default frequency. If the donation frequency
 * doesn’t exist in "donationFrequencies", do nothing.
 *
 * For example, "recurring=12" updates the "donationFrequencies" prop so that
 * "isDefault" is True for DonationFrequency.name == "monthly".
 *
 * See RECURRING_QUERY_PARAM_MAP.
 */
function applyFrequencyOverride(
  frequencyCode: RecurringQueryParamKey,
  frequencies?: DonationFrequency[],
) {
  const frequencyName = RECURRING_QUERY_PARAM_MAP[frequencyCode]

  const matchedFrequency = frequencies?.find(
    ({ name, isVisible }) => name === frequencyName && isVisible,
  )

  if (matchedFrequency) {
    frequencies?.forEach((frequencyObj) => (frequencyObj.isDefault = false))
    matchedFrequency.isDefault = true
  }
}

/**
 * The amount override changes the default donation amount.
 *
 * - If a preset's amount matches the amount override, set its "isDefault" to True.
 * - If no presets are matched, set the "other" preset's "isDefault" to True and
 *   update its "amount" to the amount override value.
 */
function applyAmountOverride(defaultAmount: number, amountList?: DonationAmount[]) {
  if (!amountList || !amountList.length) {
    return
  }

  let matchedPresetAmount: DonationAmount | null = null
  let otherAmount: DonationAmount | null = null

  for (const amountObj of amountList) {
    // Reset all isDefault properties to false
    amountObj.isDefault = false

    if (amountObj.name.startsWith('preset') && amountObj.amount === defaultAmount) {
      matchedPresetAmount = amountObj
    }

    if (amountObj.name === 'other') {
      otherAmount = amountObj
    }
  }

  if (matchedPresetAmount) {
    matchedPresetAmount.isDefault = true
  } else if (otherAmount) {
    otherAmount.isDefault = true
    otherAmount.amount = defaultAmount
  }
}

/**
 * Default donation amounts used if Intelligent Ask Amount fails to load data
 */
export const iaaDefaultAmounts = {
  donationAmounts: [
    {
      name: 'preset1' as AmountInput,
      amount: 25,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset2' as AmountInput,
      amount: 50,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset3' as AmountInput,
      amount: 100,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset4' as AmountInput,
      amount: 500,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'other' as AmountInput,
      amount: 0,
      isVisible: true,
      isDefault: false,
    },
  ],
  recurringAmounts: [
    {
      name: 'preset1' as AmountInput,
      amount: 25,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset2' as AmountInput,
      amount: 45,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset3' as AmountInput,
      amount: 70,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'preset4' as AmountInput,
      amount: 90,
      isVisible: true,
      isDefault: false,
    },
    {
      name: 'other' as AmountInput,
      amount: 0,
      isVisible: true,
      isDefault: false,
    },
  ],
}
