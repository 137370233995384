/**
 * Fetch a specific supporter by member id
 */
export const getSupporterByMemberId = async (organizationId: string, memberId: string) => {
  const response = await fetch(
    `/api/organizations/${organizationId}/supporters?filter=member_id=${memberId}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  const json = await response.json()

  return json
}
