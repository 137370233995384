import React from 'react'
import NextImage from 'next/image'
import {
  Footer,
  ClassyFooter,
  PageHeader,
  ComputedUserTheme,
  useCustomEventListener,
  Metadata,
  Member,
} from '@classy/campaign-page-blocks'
import { BlockBuilder } from 'features/Block/BlockBuilder'
import { MemberDropdown } from './MemberDropdown'
import { useSsoMember } from 'hooks/useSsoMember'
import { DenormalizedPageData, PageConfig } from 'models/pages'
import { EVENT } from 'models/event'
import { SsoSdk } from './SsoSdk'
import { getCampaignProgressMetrics } from 'services/campaign'
import { onLogin } from 'services/sso'
import {
  trackAddToCartEvent,
  AnalyticsTrackAddToCartEvent,
} from 'services/analytics/events/addToCart'
import { trackCustomPageView } from 'services/analytics/events/customPageView'
import { trackPageViewEvent } from 'services/analytics/events/pageView'
import { useConsentManager } from 'services/transcend'
import { CartController } from 'features/Cart'
import { logger } from 'utils/logger'
import { useOptimizely } from 'hooks/useOptimizely'
import { getFooter } from 'features/Block/footer/footer'
import { getPageHeader } from 'features/Block/page-header/page-header'

export interface CampaignPageProps
  extends Pick<DenormalizedPageData, 'theme' | 'sharedBlocks' | 'pageData'> {
  isAnalyticsLoaded?: boolean
  pageConfig: PageConfig
  metadata?: Metadata
}

/**
 * This component assumes all campaign pages share the same layout.
 */
export const CampaignPage = ({
  pageData,
  sharedBlocks,
  theme,
  pageConfig,
  metadata,
  isAnalyticsLoaded,
}: CampaignPageProps) => {
  const [isSsoSdkLoaded, setIsSsoSdkLoaded] = React.useState(false)
  const [clientIpAddress, setClientIpAddress] = React.useState('')

  /**
   * TODO CL-28526, attach NextImage to the blocks that actually use it.
   * Currently (5/8/24) used by PageHeader, Footer, Image, and Impact.
   * PageHeader and Footer can simply be injected below (since they live outside BlockBuilder).
   * For page blocks:
   * Idea 1: Use getBlocksMap(pageData) and inject into the block props (con: map is built twice)
   * Idea 2: Inject it via BlockBuilder, import NextImg; if (type===Image) { add NextImg to props }
   */
  const metaWithNextComponents = {
    ...metadata,
    // TODO: Fix the type incompatibility here 😢
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- unclear how to fix this
    NextImage: NextImage as any,
  }

  useOptimizely(pageConfig.orgId)

  const pageHeaderProps = getPageHeader(sharedBlocks)?.props
  const footerProps = getFooter(sharedBlocks)?.props

  const member = useSsoMember(isSsoSdkLoaded)

  /**
   * Fetching the client's IP address client side, instead of getServerSideProps
   * to prevent caching the IP address.
   */

  const fetchClientIpAddress = React.useCallback(async () => {
    const response = await fetch('/api/clientIpAddress/')
    const responseJson = await response.json()

    setClientIpAddress(responseJson.ip)
  }, [])

  React.useEffect(() => {
    fetchClientIpAddress()
  }, [fetchClientIpAddress])

  const { airgap } = useConsentManager()

  // * GA4 Custom Page View Tracking Event
  React.useEffect(() => {
    if (airgap && isAnalyticsLoaded && member !== undefined) {
      trackCustomPageView(pageConfig, member)
    }
  }, [airgap, isAnalyticsLoaded, pageConfig, member])

  /**
   * Track PageView Event for Facebook Pixel and Meta CAPI
   */
  React.useEffect(() => {
    /**
     * Fire trackPageViewEvent if airgap, isAnalyticsLoaded, clientIpAddress are true and
     * only if member !== undefined. If member === undefined, then useSsoMember has not
     * finished checking whether a member is logged in or not.
     */
    if (airgap && isAnalyticsLoaded && clientIpAddress && member !== undefined) {
      trackPageViewEvent({ member, client_ip: clientIpAddress })
    }
  }, [airgap, clientIpAddress, isAnalyticsLoaded, member])

  /**
   *   Custom Event Listener for Facebook Pixel and Meta CAPI trackAddToCartEvent analytics
   */
  const addToCartEventData = useCustomEventListener<AnalyticsTrackAddToCartEvent | null>(
    EVENT.ANALYTICS_TRACK_ADD_TO_CART,
    null,
  )

  React.useEffect(() => {
    if (addToCartEventData && addToCartEventData.newCartItem) {
      const { newCartItem, cartCount } = addToCartEventData

      trackAddToCartEvent({
        newCartItem,
        cartCount,
        clientIpAddress: clientIpAddress,
        member,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartEventData])

  // Used for A/B testing by Cro Metrics. The SC type name comes from an appv2 convention and stands
  // for StayClassy (the old name of the company).
  React.useEffect(() => {
    window.SC = {
      ab_testing: {
        cid: Number(pageConfig.campaignId),
        oid: Number(pageConfig.orgId),
        donation_type: 'studio',
        campaign_type: 'studio',
      },
    }
  }, [pageConfig.campaignId, pageConfig.orgId])

  // * Fetch campaign progress metrics from the search service
  const campaignProgressMetrics = React.useCallback(async () => {
    try {
      if (pageConfig.campaignId) {
        const { donorCount, goal, percentToGoal, totalRaised } = await getCampaignProgressMetrics(
          pageConfig.campaignId,
        )

        // * Dispatch campaign data for interested blocks
        document.dispatchEvent(
          new CustomEvent(EVENT.SEARCH_GET_CAMPAIGN_PROGRESS_METRICS, {
            detail: {
              donorCount,
              goal,
              percentToGoal,
              totalRaised,
            },
          }),
        )
      }
    } catch (e) {
      logger(
        'error',
        new Error('Unable to fetch campaign details from Classy Search', { cause: e }),
      )
    }
  }, [pageConfig.campaignId])

  React.useEffect(() => {
    campaignProgressMetrics()
  }, [campaignProgressMetrics])

  // Member info is a truncated member object for use by page blocks. See GlobalBlockProps for info.
  let memberInfo: Member | undefined
  if (member) {
    memberInfo = {
      id: member.id,
      avatar: member.thumbnail_small,
    }
  }

  return (
    <>
      <ComputedUserTheme theme={theme} />
      {pageHeaderProps && (
        <PageHeader hidePlaceholder NextImage={NextImage as never} {...pageHeaderProps}>
          {pageConfig.isCartEnabled && pageConfig.campaignId && pageConfig.checkoutBaseUrl && (
            <CartController
              checkoutBaseUrl={pageConfig.checkoutBaseUrl}
              checkoutQueryParams={pageConfig.forwardCheckoutQueryParams}
              campaignId={pageConfig.campaignId}
            />
          )}
          {member && <MemberDropdown member={member} orgId={pageConfig.orgId} />}
          <SsoSdk onReady={() => setIsSsoSdkLoaded(true)} pageConfig={pageConfig} />
        </PageHeader>
      )}
      <BlockBuilder pageData={pageData} metadata={metaWithNextComponents} member={memberInfo} />
      <footer>
        {footerProps && <Footer NextImage={NextImage as never} {...footerProps} />}
        <ClassyFooter
          data-id="cp-footer-login"
          isLoggedIn={!!member}
          onLogin={() => onLogin(pageConfig.orgId)}
        />
      </footer>
    </>
  )
}
