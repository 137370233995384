import { whammyFetch } from 'classy-api-proxies/whammy'
import { FeedItem, FeedItemPageResponse } from 'features/Block/activity-feed/feed-item.model'

/**
  For reference.
  TODO: Clean up once most endpoints are wired up.

  Feed Items:
  add feed item      POST api/campaigns/[campaignId]/feed-items.ts
  delete feed item   DELETE api/feed-items/[feedItemId]/index.ts
  like feed item     POST api/feed-items/[feedItemId]/likes.ts
  unlike feed item   DELETE api/likes/[likeId].ts

  Comments (i.e. replies):
  get comments       GET api/feed-items/[feedItemId]/comments.ts
  add comment        POST api/feed-items/[feedItemId]/comments.ts
  delete comment     DELETE api/comments/[commentId]/index.ts
  like comment       POST api/comments/[commentId]/likes.ts
  unlike comment     DELETE api/likes/[likeId].ts
  */

/**
 * Fetch initial activity feed items.
 * Primarily for when the activity feed is initally loaded.
 */
export const getInitialFeedItems = async (campaignId: string) => {
  const result = await whammyFetch(`/campaigns/${campaignId}/feed-items`, {
    method: 'GET',
  })

  const json: FeedItemPageResponse = await result.json()

  return {
    items: json.data,
    total: json.total,
  }
}

/**
 * Adds a single feed item (i.e. post/comment).
 */
export const addFeedItem = async (campaignId: string, body: string) => {
  const result = await whammyFetch(`/campaigns/${campaignId}/feed-items`, {
    method: 'POST',
    body: JSON.stringify({
      comment: body,
    }),
  })

  // TODO CL-37803, this does not return the member object, ?with=member did not work

  const json: FeedItem = await result.json()

  return json
}
