import React from 'react'
import { ActivityFeed, ActivityFeedProps } from '@classy/campaign-page-blocks'
import { useWhammyDispatch } from './useWhammyDispatch'
import { useActivityFeedReducer } from './useFeedItemsReducer'
import { logger } from 'utils/logger'

interface ActivityFeedControllerProps extends ActivityFeedProps {}

/**
 * ActivityFeed page block wrapper.
 *
 * See https://bitbucket.org/stayclassy/campaign-page-blocks/pull-requests/657 for a useful
 * overview diagram.
 */
export const ActivityFeedController = ({ ...rest }: ActivityFeedControllerProps) => {
  const { campaignId, member } = rest
  const [, dispatch] = useActivityFeedReducer()

  if (!campaignId) {
    throw new Error('Activity feed requires a campaigin id.')
  }

  const [isLoading, setIsLoading] = React.useState(true)
  const { feedItems, whammyDispatch } = useWhammyDispatch(campaignId)

  React.useEffect(() => {
    // Fetch initial user comments and autogenerated feed items
    ;(async () => {
      if (!isLoading) return

      try {
        await whammyDispatch({ type: 'GET_INITIAL_FEED_ITEMS' })
      } catch (e) {
        logger(
          'error',
          new Error("Unable to load the activity feed's initial items", { cause: e }),
          {
            context: {
              campaignId,
            },
          },
        )
        // Per product/design, if there is an API error, only show the empty state
        dispatch({
          type: 'SET_INITIAL_FEED_ITEMS_STATE',
          state: {
            items: [],
            total: 0,
          },
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [campaignId, dispatch, isLoading, whammyDispatch])

  return (
    <ActivityFeed
      {...rest}
      isLoading={isLoading}
      feedItems={feedItems.items}
      totalFeedItems={feedItems.total}
      whammyDispatch={whammyDispatch}
      member={member}
    />
  )
}
