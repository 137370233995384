// * Here we wrap ProgressMetrics block in some logic to listen for events
import React from 'react'
import { ProgressMetrics, useCustomEventListener } from '@classy/campaign-page-blocks'
import { EVENT } from 'models/event'

interface ProgressMetricsData {
  donorCount: number
  goal: number
  percentToGoal: number
  totalRaised: number
}

export const ProgressMetricsHoc = ({ ...rest }) => {
  const { donorCount, goal, percentToGoal, totalRaised }: ProgressMetricsData =
    useCustomEventListener(EVENT.SEARCH_GET_CAMPAIGN_PROGRESS_METRICS, {
      donorCount: 0,
      goal: 0,
      percentToGoal: 0,
      totalRaised: 0,
    })

  return (
    <ProgressMetrics
      donorCount={donorCount}
      goal={goal}
      percentToGoal={percentToGoal}
      totalRaised={totalRaised}
      {...rest}
    />
  )
}
