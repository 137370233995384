import React from 'react'
import { Delete } from '@classy/campaign-page-blocks'
import { FREQUENCY_LABELS, type Frequency } from '@classy/campaign-page-blocks'
import * as Styled from './CartItem.styled'

interface CartItemProps {
  id: string
  title: string
  description?: string
  frequency?: Frequency
  amount: number
  currency: string
  onDelete: (id: string) => void
  endDate?: string
}

export const CartItem = ({
  id,
  title,
  description,
  frequency,
  amount,
  currency,
  onDelete,
  endDate,
}: CartItemProps) => {
  const [isFadingOut, setIsFadingOut] = React.useState(false)
  const cartItemCardRef = React.useRef<HTMLDivElement>(null)

  const handleRemoveItem = () => {
    setIsFadingOut(true)

    const cartItemCard = cartItemCardRef.current

    cartItemCard?.addEventListener(
      'transitionend',
      () => {
        onDelete(id)
      },
      { once: true },
    )
  }

  return (
    <Styled.Card
      ref={cartItemCardRef}
      className={isFadingOut ? 'fadeout' : ''}
      // 105px is the height of a cart item card where the header/description are only one line
      cartItemCardHeight={cartItemCardRef.current?.clientHeight || 105}
    >
      <Styled.InfoContainer>
        <Styled.Title>{title}</Styled.Title>
        <Styled.ButtonContainer onClick={handleRemoveItem} aria-label="Remove cart item">
          <Delete />
        </Styled.ButtonContainer>
      </Styled.InfoContainer>

      {description && <Styled.Description>{description}</Styled.Description>}
      <Styled.InfoContainer>
        <Styled.FrequencyBadge>
          <Styled.Frequency>
            {frequency ? FREQUENCY_LABELS[frequency] : 'Error'}
            {endDate
              ? // new Date('yyyy-mm-ddT00:00:00') will generate a date object for midnight on the
                // user's timezone, which will prevent a time shift when formatting it.
                ' until ' + new Intl.DateTimeFormat('en-US').format(new Date(`${endDate}T00:00:00`))
              : null}
          </Styled.Frequency>
        </Styled.FrequencyBadge>
        <Styled.Amount>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          }).format(amount)}
        </Styled.Amount>
      </Styled.InfoContainer>
    </Styled.Card>
  )
}
