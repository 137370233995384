import { DenormalizedPageData, PageConfig } from 'models/pages'
import { PageType } from 'models/pages'
import { denormalizeLayoutWithBlocks, findCampaignPage } from './campaign'
import { Block } from 'features/Block/block.model'
import { FREQUENCY, Metadata } from '@classy/campaign-page-blocks'
import { getBlocksMap } from 'features/Block/utils/getBlocksMap'

/**
 * Temporary mock data service to pull data from JSON files.
 *
 * ! Do not nuke this mocking service yet, it is used by Lighthouse.
 */

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

/**
 * Returns a campaign object, or a specific page object.
 *
 * In the URL, add:
 * - "?mock=campaign" to fetch a "./data/<campaignId>/raw.json"
 * - "?mock=page" to fetch a "./data/<campaignId>/<pageType|tid>.json"
 */
export const getMockData = async (
  mockType: string,
  delay = 0,
  campaignId: string,
  pageType: PageType,
  tid?: string,
): Promise<DenormalizedPageData> => {
  await sleep(delay)

  if (mockType === 'page') {
    return getMockPageData(campaignId, pageType, tid)
  }

  return getMockCampaignData(campaignId, pageType, tid)
}

interface MockMetadata {
  pageConfig: Partial<PageConfig>
  metadata: Partial<Metadata>
}

export const getMockPageConfig = async (
  campaignId: string,
  pageType: string,
): Promise<MockMetadata> => {
  const pageConfig: Partial<PageConfig> = {
    campaignId,
    campaignName: 'Fake Campaign',
    checkoutBaseUrl: 'https://mock.org',
    orgId: 'fakeOrgId',
    orgName: 'Fake Organization',
  }

  const metadata: Partial<Metadata> = {
    campaignId,
    orgId: 'fakeOrgId',
    socialShareLinks: mockSocialShareLinks,
  }

  if (pageType === 'donation') {
    metadata.donation = mockDonation
  }

  // Mock gift catalog campaign. Real campaign id needed to make Cart API calls.
  if (pageType === 'donation' && campaignId === 'gcpoc') {
    pageConfig.isCartEnabled = true
    metadata.isCartEnabled = true
    pageConfig.campaignId = '524268'
    metadata.campaignId = '524268'
    metadata.donation = {
      ...metadata.donation,
      defaultProgramDesignation: {
        id: 225056,
        name: 'Bike Fund #1',
        isDefault: true,
      },
    }

    /**
     * The "id-impact-*" keys below are real block ids tied to ./data/gcpoc/raw.json.
     * Note, in raw.json, the two same Impact blocks are reused throughout the page. In real
     * campaign page data, all blocks are unique.
     */
    metadata.impact = {
      programDesignations: {
        'id-impact-with-one-designation': [{ id: 225056, name: 'Bike Fund #1', isDefault: true }],
        'id-impact-with-two-designations': [
          { id: 225056, name: 'General Fund Project', isDefault: true },
          { id: 2, name: 'SELECT ME IF YOU WANT TO GET A CART API ERROR', isDefault: false },
        ],
      },
    }
  }

  return { pageConfig, metadata }
}

/**
 * Returns a single page, already denormalized (layout and blocks merged)
 */
export const getMockPageData = async (
  campaignId: string,
  pageType: PageType | string,
  tid?: string,
): Promise<DenormalizedPageData> => {
  const page = tid || pageType
  const { theme, sharedBlocks, pageData } = await import(`../../data/${campaignId}/${page}.json`)
  return { campaign_id: campaignId, theme, sharedBlocks, pageData }
}

/**
 * Returns a full campaign dataset.
 */
export const getMockCampaignData = async (
  campaignId: string,
  pageType: PageType,
  tid?: string,
): Promise<DenormalizedPageData> => {
  const campaign = await import(`../../data/${campaignId}/raw.json`)
  const { theme, sharedBlocks } = campaign
  const page = findCampaignPage(campaign, pageType, tid)
  const pageData = page ? denormalizeLayoutWithBlocks(page) : ({} as Block)

  const blocksMap = getBlocksMap(pageData)

  // Mock activity feed. Real campaign id needed to make API calls.
  if (pageType === 'donation' && campaignId === 'activity-feed') {
    /* @ts-expect-error - we control the demo page, we know the following exists */
    blocksMap['activity-feed'][0].props.campaignId = '536228'
  }

  return { campaign_id: campaignId, theme, sharedBlocks, pageData }
}

/**
 * ! In the mock campaign data JSON (i.e. raw.json), we name each "pages" key the same
 * ! as its route. For the moment, this is to make it easier to scan and edit the file.
 *
 * This method is only useful for mock thank you pages. Other mock pages match by "type", not
 * page id.
 */
export const getMockPageKey = (campaignId: string, pageType: PageType, tid?: string) => {
  let pageId = undefined

  if (tid) {
    pageId = `${campaignId}/${pageType}/${tid}`
  }

  return pageId
}

export const mockDonation = {
  checkoutBaseUrl: 'https://mock.org',
}

export const mockSocialShareLinks = {
  sms: `sms:?&body=${encodeURIComponent('dev share via SMS test!')}`,
  email: `mailto:?body=${encodeURIComponent(
    'dev share via Email test!',
  )}&subject=${encodeURIComponent('dev testing')}`,
  twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `dev share to Twitter (I guess X now lol) test!`,
  )}&url=${encodeURIComponent('https://dev.classy-test.org')}`,
  linkedin: 'https://www.linkedin.com',
  facebook: `https://www.facebook.com/dialog/share?app_id=${
    process.env.FACEBOOK_APP_ID
  }&display=popup&href=${encodeURIComponent(
    `https://dev-giving.classy-test.org/campaign/phillyzoo/donate?mock=campaign`,
  )}&redirect_uri=${encodeURIComponent(
    `https://dev-giving.classy-test.org/campaign/phillyzoo/thank-you?mock=campaign`,
  )}`,
  facebookText: 'Facebook Text Share Dev Test',
  facebookImageUrl:
    'https://staging-assets.classy-test.org/4468920/24d31116-37c3-11ee-8db0-0a58a9feac02.webp',
  copy: 'https://dev-giving.classy-test.org/campaign/phillyzoo',
}

export const mockTransactionDetails = {
  amount: '$205.50 USD',
  email: 'donor@classy.org',
  confirmationNumber: '4444',
  href: `${process.env.CLASSY_BASE_URI}/profile/60309/contribution-history`,
  donorCoveredFee: '$5.50',
  orderItems: [
    {
      id: 'id1',
      unitPrice: '$200.00',
      designationName: 'General Fund',
      frequency: FREQUENCY.DAILY,
      timeZone: 'America/Los_Angeles',
    },
  ],
}

// Can use this when developing locally
export const mockTransactionCookie = {
  id: '123abc',
  frequency: 'one-time',
  raw_currency_code: 'USD',
  raw_total_gross_amount: '205',
  member_email_address: 'email@email.com',
  raw_adjustment_amount: '5',
  designation_id: 225031,
}
