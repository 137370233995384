import React from 'react'
import { Cart, CartItem } from 'features/Cart'
import { initCart, isNewCart, getCart } from 'services/cart/cart'
import { hasLocalStorage } from 'utils/environment'
import { logger } from 'utils/logger'

/**
 * Cart state, and local storage caching.
 */

export type CartAction =
  | { type: 'ADD_CART_ITEM'; cartItem: CartItem }
  | { type: 'REMOVE_CART_ITEM'; cartItemId: string }
  | { type: 'REPLACE_CART'; newCart: Cart }
  | { type: 'DELETE_LOCAL_STORAGE' }

/**
 * Cart state reducer actions.
 */
export function cartReducer(state: Cart, action: CartAction): Cart {
  switch (action.type) {
    case 'ADD_CART_ITEM': {
      const items = state.items ? [...state.items] : []
      items.push(action.cartItem)

      return {
        ...state,
        items,
      }
    }

    case 'REMOVE_CART_ITEM': {
      const items = state.items ? state.items.filter((item) => item.id !== action.cartItemId) : []

      return {
        ...state,
        items,
      }
    }

    case 'REPLACE_CART': {
      return {
        ...action.newCart,
      }
    }

    case 'DELETE_LOCAL_STORAGE': {
      window.localStorage.removeItem(getLocalStorageKey(state.campaignId))
      return initCart(state.campaignId)
    }

    default:
      throw new Error(`Unknown action type: ${(action as CartAction).type}`)
  }
}

export function getLocalStorageKey(campaignId: string) {
  return `gift-catalog-cart-${campaignId}`
}

/**
 * Hook that combines a useReducer and local storage caching.
 */
export function useCartLocalStorageReducer(
  campaignId: string,
  initialState: Cart,
  setIsLoading: (isLoading: boolean) => void,
): [Cart, React.Dispatch<CartAction>] {
  const key = React.useMemo(() => getLocalStorageKey(campaignId), [campaignId])
  const [state, dispatch] = React.useReducer(cartReducer, initialState)

  //  Check if a cart already exists in local storage. If so, fetch the cart from the Cart API on
  //  page load to sync the cart states since the cart can be updated outside of whammy (e.g. during
  //  the checkout process)
  React.useEffect(() => {
    if (!hasLocalStorage()) {
      return
    }
    const localStorageCart = window.localStorage.getItem(key)

    const fetchCartFromCartApi = async () => {
      try {
        if (localStorageCart) {
          const parsedLocalStorageCart = JSON.parse(localStorageCart)

          logger('trace', 'Fetching existing cart from local storage on page load', {
            context: { cart: parsedLocalStorageCart },
          })

          // Fetch the cart from the Cart API using the id of the cart in local storage
          // and update local storage to ensure local and external cart states are synced
          const externalCart = await getCart(parsedLocalStorageCart?.id, campaignId)
          dispatch({ type: 'REPLACE_CART', newCart: externalCart })
        }
      } catch (e) {
        logger('error', new Error('Unable to fetch existing cart on page load', { cause: e }), {
          context: { cart: JSON.parse(localStorageCart || '{}') },
        })

        dispatch({ type: 'DELETE_LOCAL_STORAGE' })
      } finally {
        setIsLoading(false)
      }
    }

    fetchCartFromCartApi()
  }, [campaignId, key, setIsLoading])

  /**
   * Update local storage whenever the cart state is updated.
   */
  React.useEffect(() => {
    if (!hasLocalStorage() || isNewCart(state)) {
      return
    }

    localStorage.setItem(key, JSON.stringify(state))
  }, [key, state])

  /**
   * On the client, listen for storage changes from other tabs.
   *
   * The storage event of the Window interface fires when a storage area has been modified in the
   * context of another document. Note, the storage event fires on other documents, not the same
   * document that is making the changes.
   */
  React.useEffect(() => {
    const updateCartState = (storageEvent: StorageEvent) => {
      if (storageEvent.key !== key) {
        return
      }

      if (storageEvent.newValue) {
        dispatch({ type: 'REPLACE_CART', newCart: JSON.parse(storageEvent.newValue) })
      }
    }

    window.addEventListener('storage', updateCartState)

    // cleanup event listener
    return () => window.removeEventListener('storage', updateCartState)
  }, [key])

  return [state, dispatch]
}

/**
 * Hook for removing the cart state from local storage.
 */
export function useCartLocalStorageFlusher(isCartEnabled: boolean, campaignId: string) {
  const key = React.useMemo(
    () => isCartEnabled && getLocalStorageKey(campaignId),
    [isCartEnabled, campaignId],
  )

  React.useEffect(() => {
    if (!isCartEnabled || !key) {
      return
    }

    window.localStorage.removeItem(key)
  })
}
