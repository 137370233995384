import { useImmerReducer } from 'use-immer'
import { ActivityFeedItemWithReplies, ActivityFeedItemsState } from '@classy/campaign-page-blocks'

/**
 * Actions for updating the activity feed items list.
 */
export type FeedItemsAction =
  // System generated feed items and user comments ("FeedItem" in APIv2 land)
  | { type: 'SET_INITIAL_FEED_ITEMS_STATE'; state: ActivityFeedItemsState }
  // | { type: 'LIKE_FEED_ITEM'; feedItemId: string }
  // | { type: 'UNLIKE_FEED_ITEM'; likeId: string }
  // User comments ("FeedItem" in APIv2 land)
  | { type: 'ADD_USER_COMMENT'; comment: ActivityFeedItemWithReplies }
// | { type: 'DELETE_USER_COMMENT'; feedItemId: string }
// Replies ("Comment" in APIv2 land)
// | { type: 'ADD_REPLY'; feedItem: ActivityFeedItem }
// | { type: 'DELETE_REPLY'; replyId: string }
// | { type: 'LIKE_REPLY'; replyId: string }
// | { type: 'UNLIKE_REPLY'; likeId: string }

const INITIAL: ActivityFeedItemsState = {
  items: [],
  total: 0,
}

/**
 * Activity feed items list reducer.
 *
 * See https://bitbucket.org/stayclassy/campaign-page-blocks/pull-requests/657 for a useful
 * overview diagram.
 */
function reducer(draft: ActivityFeedItemsState, action: FeedItemsAction) {
  switch (action.type) {
    case 'SET_INITIAL_FEED_ITEMS_STATE': {
      return action.state
    }
    case 'ADD_USER_COMMENT': {
      draft.items.unshift(action.comment)
      draft.total++
      break
    }
    default:
      /* @ts-expect-error -- TS complains about action.type because all actions are covered 💪 */
      throw new Error(`Unknown action: ${action.type}`)
  }
}

export const useActivityFeedReducer = () => useImmerReducer(reducer, INITIAL)
