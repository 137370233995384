import { getAnalyticsInstance } from '../analytics'
import { PageConfig } from 'models/pages'
import { Member } from 'models/member'
import { getSupporterByMemberId } from 'services/organization'

export const trackCustomPageView = async (pageConfig: PageConfig, member: Member | null) => {
  const analytics = getAnalyticsInstance()
  let supporter = undefined

  if (member) {
    supporter = await getSupporterByMemberId(pageConfig.orgId, member.id)
  }

  analytics.track(
    'custom_page_view',
    {
      campaign: {
        id: pageConfig.campaignId,
        name: pageConfig.campaignName,
        created_at: pageConfig.campaignCreatedAt,
        campaign_currency_code: pageConfig.campaignRawCurrencyCode,
        default_designation_id: pageConfig.defaultDesignationId,
        campaign_goal: pageConfig.campaignRawGoal,
        type: 'studio',
        features: pageConfig.isCartEnabled ? ['cart'] : [],
      },
      supporter: {
        id: supporter?.supporter_id,
      },
    },
    {
      plugins: {
        all: false,
        'google-analytics': true,
      },
    },
  )
}
