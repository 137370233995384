import { customAlphabet } from 'nanoid'
import { Member } from 'models/member'
import { getAnalyticsInstance } from '../analytics'

type PageViewContext = {
  member: Member | null
  client_ip: string
}

/**
 * Track PageView Event
 */
export const trackPageViewEvent = (context: PageViewContext) => {
  const { member, client_ip } = context

  const analytics = getAnalyticsInstance()

  /**
   * Generate a unique event id for Facebook Pixel and Meta CAPI, so duplicate events are not sent
   */
  const generateEventId = customAlphabet('0123456789', 12)
  const eventId = `${generateEventId()}.${generateEventId()}`
  const getEventTime = () => Math.round(new Date().getTime() / 1000)

  // For both Facebook and Meta CAPI
  analytics.page({
    event_id: eventId,
    event_time: getEventTime(),
    email: member?.email_address ?? '',
    first_name: member?.first_name ?? '',
    last_name: member?.last_name ?? '',
    client_ip: client_ip ?? '',
  })
}
